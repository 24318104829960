<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <div class="mt-5 d-flex">
            <v-btn class="btn-invisible mr-5" @click.stop="showModal('reparation-doudoune')">
              <v-icon class="mx-3" color="black darken-2" dark small>
                mdi-information
              </v-icon>
            </v-btn>
            <p>
              La réparation simple peut être un patch, une couture simple ou encore un empiècement.<br/>
              Visible mais
              discrète, cette réparation est plus abordable que la cloison. À réception, nous choisissons la
              réparation la plus adaptée à votre déchirure.
            </p>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col v-for="(option, index) in options" :key="option.value" cols="12">
              <v-row>
                <v-col class="text-center" cols="6" md="3" offset-md="3">{{ option.label }}</v-col>
                <v-col class="text-center" cols="6" md="3">
                  <plus-minus-field
                    :index="index"
                    :price="option.price"
                    :value="detailQte[index]"
                    @input="e => getInfos(e, option.bonus)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <p>
            Pour les déchirures de plus de 5 cm, nous vous conseillons une
            <a class="font-weight-bold" @click="$emit('changeTab', 'empiecement')">cloison</a>.
          </p>
        </v-col>
        <v-col cols="12">
          <v-checkbox v-model="plumUp" label="Remplumer la doudoune là où il y a une déchirure" @click="getPrice()"/>
        </v-col>
        <v-col cols="12">
          <h6>EMPLACEMENT</h6>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="emplacement"
            name="input-7-4"
            outlined
            placeholder="Indiquez l'emplacement"
          />
        </v-col>
        <v-col cols="12">
          <image-preview
            :is-bonus-eligible="totalBonus > 0"
            :value="pictures"
            @change="v => pictures = v"
          />
        </v-col>
        <price :amount="totalPrice" :bonus="totalBonus" :display-bottom-line="totalQty > 0"/>
      </v-row>
      <bonus-image-confirm
        v-if="totalBonus > 0 && pictures.length < 2"
        @skip-bonus="totalBonus = 0"
      />
      <v-row v-else>
        <confirm-flow
          v-if="totalQty > 0"
          @finalize="noarticle"
          @new-repair="onSelectNewRepair"
          @new-article="noarticle(false)"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PlusMinusField from "@/components/interface/PlusMinusField.vue";
import ImagePreview from "@/components/interface/ImagePreview.vue";
import Price from "@/components/shared/Price.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";
import {mapMutations, mapState} from "vuex";

export default {
  name: "reparation-doudoune",
  components: {ConfirmFlow, BonusImageConfirm, Price, ImagePreview, PlusMinusField},
  data() {
    return {
      options: [
        {
          label: "-1 cm",
          value: "inferieur_1",
          price: 15,
          bonus: 7
        },
        {
          label: "1 à 5 cm",
          value: "de_1_a_5",
          price: 20,
          bonus: 7
        }
      ],
      plumUp: false,
      plumUpPrice: 5,
      totalQty: 0,
      totalPrice: 0,
      totalBonus: 0,
      detailQte: [],
      detailPrice: [],
      detailBonus: [],
      pictures: [],
      emplacement: null
    }
  },
  computed: {
    ...mapState('cart-app', ['products']),
    ...mapState('flux-app', ['flux']),
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setActiveTab', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    getInfos(value, bonus) {
      this.totalQty = 0
      this.$set(this.detailQte, value[0], value[1])
      this.$set(this.detailPrice, value[0], value[2])
      this.$set(this.detailBonus, value[0], (bonus ?? 0) * value[1])
      for (let i = 0; i < this.detailQte.length; i++) {
        this.totalQty = this.totalQty + (this.detailQte[i] ?? 0)
      }

      this.getPrice()
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal')
    },
    getPrice() {
      this.totalPrice = 0
      this.totalBonus = 0
      for (let i = 0; i < this.detailPrice.length; i++) {
        this.totalPrice += this.detailPrice[i] ?? 0
        this.totalBonus += this.detailBonus[i] ?? 0
      }

      if (this.plumUp) {
        this.totalPrice += this.plumUpPrice
      }
    },
    storeProduct() {
      this.setcptReparation(this.flux.cptReparation + 1)
      this.setActiveTab('reparation_simple')
      if (this.flux.cptProduct < 1) {
        this.setcptProduct(this.flux.cptProduct + 1)
      }

      let qteTotal = 0
      let priceTotal = 0

      for (let i = 0; i < this.detailPrice.length; i++) {
        if (this.detailPrice[i] > 0) {
          qteTotal = qteTotal + this.detailQte[i]
          priceTotal = priceTotal + this.detailPrice[i]
        }
      }

      const emplacement = [this.emplacement, this.plumUp ? 'Remplumer la cloison' : null].filter(e => !!e).join('<br />')

      this.addProduct({
        idLevel1: this.flux.idLevel1,
        level1: this.flux.level1,
        level2: this.flux.level2,
        level3: this.flux.level3,
        isDouble: this.flux.isDouble,
        id: this.flux.cptProduct,
        name: this.flux.level2,
        famille: this.flux.level1,
        reparations: [{
          id: this.flux.cptReparation,
          activeTab: 'reparation_simple',
          type: this.flux.level3,
          componentName: 'reparation-doudoune',
          emplacement,
          qte: 1,
          price: this.totalPrice,
          bonus: this.pictures.length > 1 ? this.totalBonus : 0,
          pictures: this.pictures,
          details: [
            this.detailQte,
            this.detailPrice
          ],
          elements: [{labels: this.options.map(label => label.label)}]
        }]
      })
      this.getTotalCart(this.products)
      this.setLevel3(null)
      this.setComponentName(null)
    },
    noarticle(showCart = true) {
      if (this.totalPrice <= 0) {
        return;
      }

      this.storeProduct()
      this.setcptProduct(this.flux.cptProduct + 1)
      this.setcptReparation(0)

      this.$emit('evenement', 'scroll1')

      if (showCart) {
        this.setConstat('cart')
        this.$emit('showmodal', 'cart')
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    }
  }
}
</script>

<style scoped>

</style>
